import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icDocuments from '@iconify/icons-ic/twotone-document-scanner'
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {AuthService} from "../../../../../app/core/service/auth.service";
import {Router} from "@angular/router";

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  trackById = trackById;

  items: MenuItem[] = [
    // {
    //   id: '1',
    //   icon: icAccountCircle,
    //   label: 'Мой профиль',
    //   description: 'Персональные данные',
    //   colorClass: 'text-teal',
    //   route: '/profile'
    // },
    {
      id: '1',
      icon: icDocuments,
      label: 'Мои заявки',
      description: 'Заявления на кредитование',
      colorClass: 'text-teal',
      route: '/my-applications'
    }
  ];

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit() {
  }

  setStatus(status: OnlineStatus) {
    this.cd.markForCheck();
  }

  logout() {
    this.router.navigateByUrl('/main');
    localStorage.removeItem('HEADER_USER');
    localStorage.removeItem('accessToken');
    this.authService.logout();
    this.popoverRef.close();
  }
  close() {
    this.popoverRef.close();
  }
}
