import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})

/* lets use this service for work with local storage */

export class PersistenceService {

  public static readonly HEADER_USER = 'currentUser';
  public static readonly LANG = 'lang';

  constructor(private translate: TranslateService) {
  }

  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      localStorage.setItem(key, data);
    }
  }

  get(key: string): any {
    try {
      const value = localStorage.getItem(key);
      return value !== null && value !== 'undefined' ? (JSON.parse(value) || '') : null;
    } catch (e) {
      return localStorage.getItem(key);
    }
  }

  clear(key: string): void {
    localStorage.removeItem(key);
  }

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

}
