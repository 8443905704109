import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AuthUtils} from "../../pages/pages/auth/auth.utils";
import {catchError, delay, switchMap} from "rxjs/operators";
import {ServiceCommonConstants} from "../constants/service-common.constants";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private AUTH_USERNAME = ServiceCommonConstants.AUTH_USERNAME
  private _authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $authenticated = this._authenticated.asObservable();

  constructor(private _httpClient: HttpClient, private route: Router) {
    this._authenticated.next(this.accessToken != null);
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  public check(): Observable<boolean> {
    // // Check if the user is logged in
    // if (this._authenticated) {
    //   return of(true);
    // }

    console.log(this.accessToken + ' - ', AuthUtils.isTokenExpired(this.accessToken));
    // Check the access token availability
    if (this.accessToken) {
      return of(true);
    }

    localStorage.removeItem('HEADER_USER');
    localStorage.removeItem('accessToken');

    return of(false);
  }

  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient.post('/auth/api/login/key', {
      accessToken: this.accessToken
    }).pipe(
      catchError(() =>
        // Return false
        of(false)
      ),
      switchMap((response: any) => {

        // Replace the access token with the new one if it's available on
        // the response object.
        //
        // This is an added optional step for better security. Once you sign
        // in using the token, you should generate a new one on the server
        // side and attach it to the response object. Then the following
        // piece of code can replace the token with the refreshed one.
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }

        // Store the user on the user service

        // Return true
        return of(true);
      })
    );
  }

  signInKey(req: any): Observable<any> {
    return this._httpClient.post(`/auth/api/login/key`, req).pipe(
      switchMap((response: any) => {
        if (response) {
          // Store the access token in the local storage
          this.accessToken = response.token;

          // Set the authenticated flag to true
          this._authenticated.next(true);

          // let loggedUser = response.user;

          // // Store the user on the user service
          // this._userService.user = loggedUser;

          localStorage.setItem('HEADER_USER', JSON.stringify(response));
          localStorage.setItem('refreshToken', response.token);

          of(null).pipe(
            delay(12 * 60 * 60 * 1000) // 12 часов в миллисекундах
          ).subscribe(() => this.logoutTrigger());
        }
        // Return a new observable with the response
        return of(response);
      })
    );
  }

  getByUserName(userName: string): Observable<any>{
    return this._httpClient.get(`${this.AUTH_USERNAME}/${userName}`);
  }

  logout() {
    this._authenticated.next(false);
  }

  logoutTrigger() {
    // Код, который должен быть выполнен через 12 часов после авторизации
    console.log('Сессия закончилась!');
    localStorage.removeItem('HEADER_USER');
    localStorage.removeItem('accessToken');
    this.logout();
    this.route.navigateByUrl('/');
  }
}
