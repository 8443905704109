import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AddContextPathInterceptorInterceptor implements HttpInterceptor {

  constructor() {}

  CONTEXT_PATH: string = '/auyl'
  REQUIRED_PATHS: string[] = ['auth', 'core', 'integration', 'admin', 'fs', 'ps']

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let url = request.url;
    let urlModified = this.CONTEXT_PATH + url;
    for (let reqPath of this.REQUIRED_PATHS) {
      if (url.includes(reqPath)) {
        let modifiedRequest = request.clone({
          url: urlModified
        });
        return next.handle(modifiedRequest);
      }
    }
    return next.handle(request)
  }
}
