<div class="dropdown">
  <div class="dropdown-content">
    <a (click)="close()"
       *ngFor="let item of items; trackBy: trackById"
       [routerLink]="item.route"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="item.icon"
                [ngClass]="item.colorClass"
                class="notification-icon"
                fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label" translate="personal-area"> </div>
        <div class="notification-description" translate="personal-information"></div>
      </div>
    </a>
  </div>

  <div (click)="logout()" class="dropdown-footer cursor-pointer py-2 px-6" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="text-primary font-semibold" >{{ 'exit' | translate }}</span>
  </div>
</div>
