import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PersistenceService} from "../../../services/persistence.service";

@Component({
  selector: 'vex-toolbar-lang',
  templateUrl: './toolbar-lang.component.html',
  styleUrls: ['./toolbar-lang.component.scss']
})
export class ToolbarLangComponent implements OnInit {

  currentLang: any = this.persistence.get('lang');

  constructor(private translate: TranslateService, private persistence: PersistenceService) { }

  ngOnInit(): void {
  }

  changeLang(lang: string): void {
    this.currentLang = lang;
    localStorage.setItem('lang', this.currentLang);
    this.translate.use(this.currentLang);
  }

}
