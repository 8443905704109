<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block"
     fxLayout="row"
     fxLayoutAlign="start center">
<!--    <img class="w-8 select-none" src="assets/img/demo/logo.svg">-->
    <img class="w-24" src="assets/img/main-logo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-6 rtl:pr-4 m-0 select-none">{{"main-title" | translate}}</h1>
  </a>

  <button *ngIf="isHorizontalLayout$ | async"
          [fxHide]="mobileQuery"
          color="primary"
          mat-raised-button
          type="button">ADD NEW
  </button>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span fxFlex></span>

  <div class="-mx-1 flex flex-row items-center">
    <div class="px-1">
      <vex-toolbar-lang></vex-toolbar-lang>
    </div>
    <div class="px-1">
      <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 cursor-pointer"
           *ngIf="!isLoggedIn"
           fxHide.xs
           (click)="signIn()">{{'sign-in' | translate}}</div>
      <vex-toolbar-user *ngIf="isLoggedIn"></vex-toolbar-user>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
