<!--<h1 mat-dialog-title>Внимание </h1>-->
<!--<div mat-dialog-content>-->
<!--    <p> Надо авторизоваться через ЭЦП ключ </p>-->
<!--</div>-->
<!--<div mat-dialog-actions>-->
<!--    <button mat-button (click)="closeDialog()">Отмена</button>-->
<!--    <button mat-button (click)="signIn()" cdkFocusInitial>Вход</button>-->
<!--</div>-->

<div class="relative flex flex-col w-full h-full primary-bg">

  <!-- Dismiss button -->
<!--  <ng-container>-->
<!--    <div class="absolute top-0 right-0 pt-4 pr-4">-->
<!--      <button-->
<!--        mat-icon-button-->
<!--        (click)="closeDialog()">-->
<!--&lt;!&ndash;        <mat-icon&ndash;&gt;-->
<!--&lt;!&ndash;          class="text-secondary"&ndash;&gt;-->
<!--&lt;!&ndash;          [svgIcon]="'heroicons_outline:x'"></mat-icon>&ndash;&gt;-->
<!--      </button>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!-- Content -->
  <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

    <!-- Icon -->
<!--    <ng-container>-->
<!--      <div-->
<!--        class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600">-->
<!--        <mat-icon-->
<!--          class="text-current"-->
<!--          svgIcon="warning_amber"></mat-icon>-->
<!--      </div>-->
<!--    </ng-container>-->

    <ng-container>
      <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

        <!-- Title -->
        <ng-container>
          <div
            class="text-xl leading-6 font-medium text-white"
          >
            {{ 'attention' | translate}}
          </div>
        </ng-container>

        <!-- Message -->
        <ng-container>
          <div class="text-secondary text-white">
            {{'you-need-login' | translate}}
          </div>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <!-- Actions -->
  <ng-container>
    <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

      <!-- Cancel -->
      <ng-container >
        <button class="text-white"
          mat-stroked-button (click)="closeDialog()">
          {{ 'cancel' | translate}}
        </button>
      </ng-container>

      <!-- Confirm -->
      <ng-container >
        <button
          mat-flat-button
          color="primary"
          (click)="signIn()">
          {{'login' | translate}}
        </button>
      </ng-container>

    </div>
  </ng-container>

</div>
